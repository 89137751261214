<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <b-card title="Commandes">
 
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Chercher</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="orders"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'user.name'"
          class="text-nowrap"
        >
          
          <span class="text-nowrap">{{ props.row.user.name }} {{ props.row.user.surname }}</span>
        </span>
        <span
          v-else-if="props.column.field === 'scan.name'"
          class="text-nowrap"
        >
          
          <span v-if="props.row.scan" class="text-nowrap">{{ props.row.scan.name }}</span>
          <span v-else class="text-nowrap">Aucun</span>
        </span>
        <span v-else-if="props.column.field === 'stock'">
          <b-badge v-if="props.row.stock>100" variant="light-success">
            {{ props.row.stock }}
          </b-badge>
          <b-badge v-else-if="props.row.stock==0" variant="light-danger">
            {{ props.row.stock }}
          </b-badge>
          <b-badge v-else variant="light-warning">
            {{ props.row.stock }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'status'">
          <b-badge v-if="props.row.status=='dispatched'" variant="success">Envoyée</b-badge>
          <b-badge v-else-if="props.row.status=='scanned'" variant="success">Scans effectués</b-badge>
          <b-badge v-else-if="props.row.status=='delivered'" variant="success">Livrée</b-badge>
          <b-badge v-else-if="props.row.status=='printing'" variant="success">En production</b-badge>
          <b-badge v-else-if="props.row.status=='completed'" variant="warning">Complétée</b-badge>
          <b-badge v-else-if="props.row.status=='failed'" variant="danger">échouée</b-badge>
          <b-badge v-else-if="props.row.status=='canceled'" variant="danger">Annulée</b-badge>
          <b-badge v-else variant="danger">error</b-badge>

        </span>

        
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'amount'">

          <span v-if="props.row.promotion!=null">{{props.row.amount - ((props.row.amount/100)*props.row.promotion.amount).toFixed(2)}}€</span>
          <span v-else>{{props.formattedRow[props.column.field]}}€</span>
         
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  @click="$router.push({name:'orderDetail', params: { id: props.row.id }})">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Voir</span>
              </b-dropdown-item>
            
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Page 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> sur {{ props.total }} résultats </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRows"
              v-model="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
     
      </template>
    </vue-good-table>

  </b-card>
</b-overlay>
</template>

<script>
import {BModal, BButton, VBModal,BOverlay, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BOverlay,
    BButton
  },
  data() {
    return {
      orders:[],
      pageLength: 10,
      dir: false,
      loading:true,
      totalRows:0,
      currentPage:1,
      selectedItem:null,
      columns: [
        {
          label: 'Client',
          field: 'user.name',
        },
        {
          label: 'Scan',
          field: 'scan.name',
        },
        {
          label: 'Date',
          field: 'date',
          type:'date',
          dateInputFormat:'dd/MM/yyyy',
          formatFn: function (value) {
              return value != null ? value : null
          }
        },
        {
          label: 'Total',
          field: 'amount',
          type:'number'
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

    }
  },
  methods:{
    
  },
  watch:{
    currentPage:async function(){
      this.loading=true
      let offset=this.currentPage*this.pageLength
      if(this.searchTerm!='')
      {
         var orders=await APIRequest.list('orders', null, null, this.pageLength,offset,null,this.searchTerm,null,null,localStorage.id_user_senseye_auth,null,'desc')
      }
      else
      {
        var orders=await APIRequest.list('orders', null, null, this.pageLength,offset,null,null,null,null,localStorage.id_user_senseye_auth,null,'desc')
      }
      orders=orders[0]
      this.orders=[]
      for(let i=0;i<orders.length;i++)
    {
      let item={}
      item=orders[i]
      
      let order_detail=await APIRequest.detail('orders', orders[i].id)

      if(!order_detail.user)
      {
        if(order_detail.cart!=null) {
          if(order_detail.cart.user!=null) {
            item.user={}
            item.user.name=order_detail.cart.user.name
            item.user.surname=order_detail.cart.user.surname
          }
          else {
            item.user={}
            item.user.name=''
            item.user.surname='Inconnu'
          }
        }
        else {
          item.user={}
          item.user.name=''
          item.user.surname='Inconnu'
        }
        
      }
      else {
        item.user={}
        item.user.name=order_detail.user.name
        item.user.surname=order_detail.user.surname
      }
      if(!order_detail.status)
      {
        if(order_detail.promotion!=null) {
        if(order_detail.promotion.amount==100) {
          item.status="completed"
        }
      }
      else if(order_detail.cart!=null) {
        if(order_detail.cart.promotion!=null) {
          if(order_detail.cart.promotion.amount==100) {
            item.status="completed"
          }
        
          } 
        }
      }
      else
      {
        item.status=order_detail.status
      }
      
      

      this.orders.push(item)
    }
      this.loading=false
    },
    searchTerm:async function()
    {
      this.loading=true
      this.pageLength=10
       this.currentPage=1
      let offset=this.currentPage*this.pageLength

      if(this.searchTerm!='')
      {
         var orders=await APIRequest.list('orders', null, null, this.pageLength,offset,null,this.searchTerm,null,null,localStorage.id_user_senseye_auth,null,'desc')
      }
      else
      {
        var orders=await APIRequest.list('orders', null, null, this.pageLength,offset,null,null,null,null,localStorage.id_user_senseye_auth,null,'desc')
      }

      orders=orders[0]
      this.orders=[]
      for(let i=0;i<orders.length;i++)
    {
      let item={}
      item=orders[i]
      
      let order_detail=await APIRequest.detail('orders', orders[i].id)

      if(!order_detail.user)
      {
        if(order_detail.cart!=null) {
          if(order_detail.cart.user!=null) {
            item.user={}
            item.user.name=order_detail.cart.user.name
            item.user.surname=order_detail.cart.user.surname
          }
          else {
            item.user={}
            item.user.name=''
            item.user.surname='Inconnu'
          }
        }
        else {
          item.user={}
          item.user.name=''
          item.user.surname='Inconnu'
        }
        
      }
      else {
        item.user={}
        item.user.name=order_detail.user.name
        item.user.surname=order_detail.user.surname
      }
      if(!order_detail.status)
      {
        if(order_detail.promotion!=null) {
        if(order_detail.promotion.amount==100) {
          item.status="completed"
        }
      }
      else if(order_detail.cart!=null) {
        if(order_detail.cart.promotion!=null) {
          if(order_detail.cart.promotion.amount==100) {
            item.status="completed"
          }
        
          } 
        }
      }
      else
      {
        item.status=order_detail.status
      }
      
      

      this.orders.push(item)
    }
      this.loading=false
    }
  },
  async mounted()
  {
    this.loading=true
    let orders=await APIRequest.list('orders', null, null, this.pageLength,null,null,null,null,null,localStorage.id_user_senseye_auth,null,'desc')
    this.totalRows=orders[1]
    orders=orders[0]

    
    this.orders=[]
    for(let i=0;i<orders.length;i++)
    {
      let item={}
      item=orders[i]
      
      let order_detail=await APIRequest.detail('orders', orders[i].id)

      if(!order_detail.user)
      {
        if(order_detail.cart!=null) {
          if(order_detail.cart.user!=null) {
            item.user={}
            item.user.name=order_detail.cart.user.name
            item.user.surname=order_detail.cart.user.surname
          }
          else {
            item.user={}
            item.user.name=''
            item.user.surname='Inconnu'
          }
        }
        else {
          item.user={}
          item.user.name=''
          item.user.surname='Inconnu'
        }
        
      }
      else {
        item.user={}
        item.user.name=order_detail.user.name
        item.user.surname=order_detail.user.surname
      }
      if(!order_detail.status)
      {
        if(order_detail.promotion!=null) {
        if(order_detail.promotion.amount==100) {
          item.status="completed"
        }
      }
      else if(order_detail.cart!=null) {
        if(order_detail.cart.promotion!=null) {
          if(order_detail.cart.promotion.amount==100) {
            item.status="completed"
          }
        
          } 
        }
      }
      else
      {
        item.status=order_detail.status
      }
      
      

      this.orders.push(item)
    }
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
